<template>
  <div class="project_popup">
    <div class="papply_popup_content popup_content">
      <div class="close_btn" @click="close">
        <span></span>
        <span></span>
      </div>
      <h3>프로젝트 지원하기</h3>
      <h4>
        {{ project.projectNm }}
      </h4>
      <div class="papply_detail">
        <div class="title">
          <p>진행방식</p>
          <p>업무 분야</p>
          <p>세부 분야</p>
          <p>프로젝트 금액</p>
          <p>프로젝트 기간</p>
        </div>
        <div class="desc">
          <p v-if="project.progress == 1">상주(파견) 희망</p>
          <p v-if="project.progress == 2">도급 희망</p>
          <p v-if="project.progress == 0">기타</p>
          <p>{{ project.work.nm }}</p>
          <p>{{ project.task.nm }}</p>
          <p>
            {{ project.projectMinPrice | comma }}원 ~
            {{ project.projectMaxPrice | comma }}원
          </p>
          <p>
            {{ project.projectStartDt }} ~ {{ project.projectEndDt }} /
            {{ project.projectPeriod }}일
          </p>
        </div>
      </div>
      <textarea
        name="coverletter"
        id="coverletter"
        placeholder="프로젝트에 지원하고자 하는 내용을 작성해 주세요."
        v-model="applyContent"
      ></textarea>
      <h4>해당 프로젝트에 지원하시겠습니까?</h4>
      <div class="papply_detail_btn btn">
        <button type="button" @click="formSubmit">지원하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import { mapGetters } from "vuex";

export default {
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {
      url: `/api/project/apply/${this.project.projectCd}`,
      applyContent: "",
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    formSubmit() {
      if (confirm("프로젝트에 지원하시겠습니까?")) {
        this.create();
      }
    },
    create() {
      let params = {
        applyContent: this.applyContent,
      };
      client.projectApplyReg(this.project.projectCd, params).then(
        () => {
          alert(
            "정상적으로 지원되었습니다. 담당자 확인 후 별도 연락 드리겠습니다."
          );
          this.close();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    close() {
      this.$emit("close");
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
