<template>
  <main class="project_detail">
    <ProjectHead />
    <div class="pdetail_heading">
      <div class="pdetail_heading_wrap">
        <h3>{{ project.projectNm }}</h3>
        <p>
          등록일 : <span>{{ project.regDtTm }}</span> ᅵ 모집기간 :
          <span v-if="this.term > 0">{{ this.term }}일</span>
          <span v-else>D - 0</span>
        </p>
        <table class="pdetail_heading_table">
          <tr>
            <th>지역구분</th>
            <td v-if="project.area">{{ project.area.nm }}</td>
            <th>모집구분</th>
            <td>
              <span v-if="project.recruit == 1 && this.term > 0">신규</span>
              <span v-if="project.recruit == 2 && this.term > 0">모집 중</span>
              <span class="pdetail_recruit_end" v-if="this.term <= 0"
                >모집 종료</span
              >
            </td>
          </tr>
          <tr>
            <th>프로젝트 진행방식</th>
            <td v-if="project.progress == 1">상주(파견) 희망</td>
            <td v-if="project.progress == 2">도급 희망</td>
            <td v-if="project.progress == 0">기타</td>
            <th>담당 파트</th>
            <td v-if="project.work">{{ project.work.nm }}</td>
            <th>업무 분야</th>
            <td v-if="project.task">{{ project.task.nm }}</td>
          </tr>
          <tr>
            <th>프로젝트 기간</th>
            <td v-if="project.projectStartDt && project.projectEndDt">
              {{ project.projectStartDt }} ~ {{ project.projectEndDt }}
            </td>
            <td v-else>협의</td>
            <th>프로젝트 금액</th>
            <td
              v-if="project.projectMinPrice && project.projectMaxPrice"
              colspan="3"
            >
              {{ project.projectMinPrice | comma }}원 ~
              {{ project.projectMaxPrice | comma }}원 / 월
            </td>
            <td v-else>협의</td>
          </tr>
          <tr>
            <th>현재 상태</th>
            <td v-if="project.state" colspan="5">{{ project.state }}</td>
            <td v-else colspan="5">협의</td>
          </tr>
        </table>
        <div class="project_apply" @click="detail(project)">
          프로젝트<br />
          지원하기
          <p>
            <img src="@/assets/icons/arrow.png" alt="arrow" />
          </p>
        </div>
      </div>
    </div>
    <div
      class="pdetail_description white_space"
      v-if="project.etcContent && project.detailContent"
    >
      <p>{{ project.etcContent }}</p>
      <p>{{ project.detailContent }}</p>
    </div>
    <div class="pdetail_description white_space" v-else>
      <p>작성된 프로젝트 상세 내용이 없습니다.</p>
    </div>
    <div class="pdetail_list_button list_button">
      <p v-if="prevTitle" class="pdetail_prev" @click="goPrev">
        <button>이전</button>
        <span>{{ prevTitle }}</span>
      </p>
      <p v-if="nextTitle" class="pdetail_next" @click="goNext">
        <button>다음</button>
        <span>{{ nextTitle }}</span>
      </p>
    </div>
    <div class="pdetail_btn">
      <button class="pdetail_list_btn" @click="goList">목록</button>
    </div>
  </main>
</template>

<script>
import ProjectHead from "@/components/ProjectHead.vue";
import ProjectPopup from "@/components/ProjectPopup.vue";
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  components: {
    ProjectHead,
  },
  data() {
    return {
      openDetail: false,
      url: "/api/project",
      project: "",
      project_cd: "",
      prevSeq: "",
      nextSeq: "",
      prevTitle: "",
      nextTitle: "",
      edt: "",
      today: this.$date().format("YYYY-MM-DD"),
      term: "",
    };
  },
  created() {
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    read() {
      this.project_cd = this.$route.params.seq;
      client.projectView(this.project_cd).then(
        (response) => {
          const { data } = response;
          data.regDtTm = data.regDtTm.slice(0, 10);
          this.project = data;
          this.edt = data.requestExpirationDt;
          this.project.projectStartDt = this.$date(data.projectStartDt).format(
            "YYYY. MM. DD"
          );
          this.project.projectEndDt = this.$date(data.projectEndDt).format(
            "YYYY.MM. DD"
          );
          this.getTerm(this.edt);
        },
        (error) => {
          alert(error);
        }
      );

      client.projectPrev(this.project_cd).then(
        (response) => {
          const { data } = response;
          this.prevTitle = data.projectNm;
          this.prevSeq = data.projectCd;
          if (this.prevTitle == undefined) {
            let prevBtn = document.querySelector(".pdetail_prev");
            prevBtn.classList.add("active");
          }
        },
        (error) => {
          console.log(error);
        }
      );

      client.projectNext(this.project_cd).then(
        (response) => {
          const { data } = response;
          this.nextTitle = data.projectNm;
          this.nextSeq = data.projectCd;
          if (this.nextTitle == undefined) {
            let nextBtn = document.querySelector(".pdetail_next");
            nextBtn.classList.add("active");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getTerm(edt) {
      let sdate = this.today.replace("-", "/");
      let edate = edt.replace("-", "/");
      let t_sdate = new Date(sdate);
      let t_edate = new Date(edate);
      let elapsedMSec = t_edate.getTime() - t_sdate.getTime();
      let elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
      this.term = elapsedDay;
    },
    goPrev() {
      this.$router.push({
        name: "ProjectSeq",
        params: {
          seq: this.prevSeq,
        },
      });
    },
    goNext() {
      this.$router.push({
        name: "ProjectSeq",
        params: {
          seq: this.nextSeq,
        },
      });
    },
    goList() {
      this.$router.push({
        name: "Project",
      });
    },
    detail(project) {
      console.log(this.term);
      if (this.term <= 0) {
        alert("지원 불가능한 프로젝트입니다.");
      } else {
        if (!this.isAuthenticated) {
          if (confirm("로그인이 필요합니다. 로그인을 하시겠습니까?")) {
            this.$router.push({
              name: "Login",
            });
          }
          return;
        }
        this.$modal.show(
          ProjectPopup,
          {
            project: project,
          },
          { width: 720, height: "auto" },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
      }
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
